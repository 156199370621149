import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { ReactComponent as Logo } from 'assets/main-logo.svg'

export default () => (
  <Fragment>
    <Container>
      <LogoContainer>
        <FooterLogo />
      </LogoContainer>
      <ContentContainer>
        <Column>
          <Heading>How can we help you?</Heading>
          <Divider />
          <Button to="/why-tugg#contact-us">GET A QUOTE</Button>
        </Column>
        <Column>
          <Heading>Services</Heading>
          <Divider />
          <NavLink to="/freight">Freight</NavLink>
          <NavLink to="/warehousing">Warehousing</NavLink>
          <NavLink to="/fulfillment">Fulfillment</NavLink>
          <NavLink to="/why-tugg">Why TUGG</NavLink>
        </Column>
        <Column>
          <Heading>Company</Heading>
          <Divider />
          <Text>Gravette Arkansas</Text>
          <Text>479-777-4307</Text>
          <Text>Hours: 9am - 5pm Mon. - Fri.</Text>
          <Button to="/why-tugg#contact-us">CONTACT</Button>
        </Column>
      </ContentContainer>
    </Container>
    <Copyright>
      Copyright © 2018 | TUGG Logistics | All Rights Reserved
    </Copyright>
  </Fragment>
)

const Container = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${props => props.theme.primaryColor};

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-wrap: wrap;
  }
`
const LogoContainer = styled.div`
  width: 30%;
  padding: 0 0 0 70px;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 170px;
    padding: 0 0 0 50px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }
`
const FooterLogo = styled(Logo)`
  height: 60px;
  width: 120px;
  fill: #fff;
`
const ContentContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: calc(100% - 180px);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 100%;
    flex-wrap: wrap;
  }
`
const Column = styled.div`
  width: 30%;
  margin: 0 1.5% 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 90%;
    margin: 0 auto;
  }
`
const Heading = styled.h2`
  display: block;
  font-size: 14pt;
  font-weight: ${props => props.theme.demi};
  color: ${props => props.theme.accentColor1};
  opacity: 0.4;

  @media (max-width: ${props => props.theme.tablet}px) {
    margin: 25px auto 0;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: white;
  opacity: 0.3;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 70%;
    align-self: center;
  }
`
const NavLink = styled(Link)`
  display: block;
  margin: 6px 0;
  font-size: 12pt;
  font-weight: ${props => props.theme.demi};
  transition-duration: 0.5s;
  color: ${props => props.theme.accentColor1};
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 11pt;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    margin: 6px auto;
  }
`
const Text = styled.p`
  display: block;
  margin: 6px 0;
  font-size: 12pt;
  font-weight: ${props => props.theme.book};
  transition-duration: 0.5s;
  color: ${props => props.theme.accentColor1};
  opacity: 0.4;
  width: 100%;

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 11pt;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    text-align: center;
  }
`
const Button = styled(Link)`
  display: block;
  margin: 8px 0;
  line-height: 0;
  padding: 20px 30px 18px;
  border-radius: 5px;
  border: 2px solid ${props => props.theme.accentColor1};
  font-size: 12pt;
  font-weight: ${props => props.theme.book};
  transition-duration: 0.5s;
  color: ${props => props.theme.accentColor1};
  opacity: 0.4;

  &:hover {
    opacity: 1;
    background-color: ${props => props.theme.accentColor1};
    color: ${props => props.theme.primaryColor};
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 11pt;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    margin: 8px auto;
  }
`
const Copyright = styled.div`
  width: 100%;
  padding: 20px 0 30px 70px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 11pt;
  font-weight: ${props => props.theme.book};
  background-color: ${props => props.theme.primaryColor};
  line-height: 24px;

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: 10px;
    text-align: center;
  }
`
