import React from 'react'
import styled from 'styled-components'
import { ResponsiveCarousel } from '@merchantlabs/react-carousel'

import ScrollAnchor from 'components/ScrollAnchor'
import Slide from './Slide'

const config = [
  { width: 0, items: 1 },
  { width: 700, items: 2 },
  { width: 1200, items: 4 }
]

export default ({ title, slides, button, anchor }) => (
  <ScrollAnchor anchor={anchor}>
    <Container>
      <Title>{title}</Title>
      <Carousel
        breakpoints={config}
        slides={slides}
        interval={8000}
        buttonComponent={ButtonComponent}
        dotComponent={Dot}
        slideRenderer={cards =>
          cards.map((slideProps, i) => <Slide key={i} {...slideProps} />)
        }
      />
    </Container>
  </ScrollAnchor>
)

const Container = styled.div`
  padding: 0 0 90px;
  background-color: #f5f5f5;
`
const Title = styled.div`
  width: 100%;
  padding: 95px 220px 45px 220px;
  letter-spacing: -2px;
  text-align: center;
  font-size: 45pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  background-color: #f5f5f5;

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 40pt;
    padding: 95px 10px 45px 10px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 25pt;
    padding: 75px 10px 25px 10px;
  }
`
const Carousel = styled(ResponsiveCarousel)`
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
`
const ButtonComponent = styled(ResponsiveCarousel.Button)`
  height: 60px;
  width: 60px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: ${props => props.theme.primaryColor};
  background: transparent;

  &:hover {
    background: ${props => props.theme.primaryColor};
    color: white;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    display: none;
  }
`
const Dot = styled(ResponsiveCarousel.Dot)`
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: ${props =>
    props.isActive ? props.theme.primaryColor : 'transparent'};
  height: 12px;
  width: 12px;
`
