import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Dots } from 'assets/divider-dots.svg'
import { ReactComponent as Scalable } from 'assets/scalable-icon.svg'
import { ReactComponent as Personalized } from 'assets/personalized-icon.svg'
import { ReactComponent as Flexible } from 'assets/flexible-icon.svg'
import { ReactComponent as Transparent } from 'assets/transparent-icon.svg'

const icons = {
  scalable: Scalable,
  personalized: Personalized,
  flexible: Flexible,
  transparent: Transparent
}

export default ({ title, text, iconName }) => {
  const Icon = styled(icons[iconName])`
    min-width: 80px;
    min-height: 80px;
    margin: 40px auto 30px;
  `

  return (
    <Container>
      <Icon />
      <Heading>{title}</Heading>
      <Divider />
      <Paragraph>{text}</Paragraph>
    </Container>
  )
}
const Container = styled.div`
  margin: 15px;
  padding: 0 30px 20px;
  width: 310px;
  height: 350px;
  border-radius: 7px 7px 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
`
const Divider = styled(Dots)`
  min-width: 60px;
  min-height: 6px;
  margin: 20px 0;
  fill: #ccc;
`
const Heading = styled.h2`
  font-size: 22pt;
  letter-spacing: -1px;
  text-align: center;
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.ultra};
  color: ${props => props.theme.primaryColor};
`
const Paragraph = styled.p`
  margin-bottom: 15px;
  text-align: center;
  font-size: 14pt;
  line-height: 18pt;
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.book};
  color: ${props => props.theme.fontColor};
`
