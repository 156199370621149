import React, { Fragment } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import theme, { GlobalStyle } from 'theme'
import SEO from 'components/SEO'
import Footer from 'components/Footer'

const TemplateWrapper = ({ children }) => {
  const { site, logo } = useStaticQuery(graphql`
    query LayoutTemplateQuery2 {
      site {
        meta: siteMetadata {
          title
        }
      }
      logo: imageSharp(original: { src: { regex: "/favicon/" } }) {
        id
        fluid {
          src
        }
      }
    }
  `)
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <Helmet>
          <title>{site.meta.title}</title>
          <link rel="icon" href={logo.fluid.src} />
        </Helmet>
        <SEO />
        <Background>
          {children}
          <Footer />
        </Background>
      </Fragment>
    </ThemeProvider>
  )
}

const Background = styled.div`
  background-color: ${props => props.theme.backgroundColor};
`

export default TemplateWrapper
