import React from 'react'
import styled from 'styled-components'

export default ({ title, text }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{text}</Subtitle>
  </Container>
)
const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 90px 0;

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 70px 0;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    margin: 50px 0;
  }
`
const Title = styled.h2`
  max-width: 600px;
  margin-bottom: 30px;
  letter-spacing: -2px;
  text-align: center;
  font-size: 45pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  background-color: ${props => props.theme.accentColor1};

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 40pt;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 25pt;
  }
`
const Subtitle = styled.div`
  width: 100%;
  padding: 0 170px;
  text-align: center;
  font-size: 20px;
  line-height: 20pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.bold};
  background-color: ${props => props.theme.accentColor1};

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 0 80px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 0 20px;
  }
`
