import React, { Fragment } from 'react'

import DesktopNav from './Desktop'
import MobileNav from './Mobile'

const mobileRoutes = [
  {
    text: 'FREIGHT',
    path: '/freight'
  },
  {
    text: 'WAREHOUSING',
    path: '/warehousing'
  },
  {
    text: 'FULFILLMENT',
    path: '/fulfillment'
  },
  {
    text: 'WHY TUGG',
    path: '/why-tugg'
  }
]

export default () => (
  <Fragment>
    <DesktopNav />
    <MobileNav routes={mobileRoutes} />
  </Fragment>
)
