import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Header from 'components/Header'
import ContentSection from 'components/ContentSection'
import SEO from 'components/SEO'
import Banner from './Banner'
import Carousel from './Carousel'
import CallToAction from './CallToAction'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const FulfillmentPageTemplate = ({
  header,
  section1,
  section2,
  section3,
  section4,
  section5
}) => (
  <Fragment>
    <Header
      images={header.images}
      number={header.number}
      title={header.title}
      subtitle={header.subtitle}
      anchor={header.scrollAnchor}
    />
    <Carousel
      title={section1.title}
      slides={section1.features}
      button={{
        path: section1.buttonPath,
        text: section1.buttonText
      }}
      anchor={header.scrollAnchor}
    />
    <Banner title={section2.title} text={section2.text} />
    <ContentSection
      image={section3.image.childImageSharp}
      title={section3.title}
      subtitle={section3.subtitle}
      text={section3.text}
    />
    <ContentSection
      image={section4.image.childImageSharp}
      title={section4.title}
      subtitle={section4.subtitle}
      text={section4.text}
      reverse="reverse"
    />
    <CallToAction
      image={section5.image.childImageSharp}
      title={section5.title}
      path={section5.buttonPath}
      buttonText={section5.buttonText}
    />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <FulfillmentPageTemplate
        header={frontmatter.header}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
        section4={frontmatter.section4}
        section5={frontmatter.section5}
      />
    </Layout>
  )
}

export const query = graphql`
  query FulfillmentPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          number
          title
          subtitle
          scrollAnchor
        }
        section1 {
          title
          features {
            title
            text
            iconName
          }
        }
        section2 {
          title
          text
        }
        section3 {
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          title
          subtitle
          text
        }
        section4 {
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          title
          subtitle
          text
        }
        section5 {
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          title
          buttonText
          buttonPath
        }
      }
    }
  }
`
