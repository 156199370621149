import { TweenMax } from 'gsap'

export default {
  /*********** animate menu tray and items ***********/

  toggleMobileNav(open, menuTray, logoRef) {
    let tray = menuTray
    let arr = Array.from(tray.children)
    let logo = logoRef

    if (open === false) {
      TweenMax.to(tray, 0.5, {
        width: 300,
        boxShadow: '-10px 0 40px rgba(0, 0, 0, 0.2)'
      })
      TweenMax.to(logo, 0, { display: 'block' })
      TweenMax.to(logo, 0.3, { delay: 0.5, opacity: 1 })
      TweenMax.to(arr, 0, { display: 'block' })
      TweenMax.staggerTo(
        arr,
        0.2,
        { delay: 0.3, opacity: 1, margin: '15px 30px' },
        0.1
      )
    } else {
      TweenMax.to(logo, 0, { opacity: 0 })
      TweenMax.to(logo, 0, { display: 'none' })
      TweenMax.to(arr, 0, { opacity: 0, margin: '50px 30px' })
      TweenMax.to(arr, 0, { display: 'none' })
      TweenMax.to(menuTray, 0.2, { width: 0 })
    }
  }
}
