import React, { Component } from 'react'

import NavContext from './nav-context.js'

export default class Desktop extends Component {
  state = {
    dropdown: null,
    left: 0,
    top: 0
  }

  onMouseLeave = () => {
    this.setState({
      dropdown: null
    })
  }

  onHoverChild = ({ dropdown, left, top }) => {
    this.setState({ dropdown, left, top })
  }

  render() {
    const { children, className, dropdownComponent: Dropdown } = this.props
    const { dropdown, left, top } = this.state

    return (
      <NavContext.Provider value={{ onHover: this.onHoverChild }}>
        <div onMouseLeave={this.onMouseLeave} className={className}>
          {children}
          {dropdown && (
            <Dropdown left={left} top={top}>
              {dropdown}
            </Dropdown>
          )}
        </div>
      </NavContext.Provider>
    )
  }
}
