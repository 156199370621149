import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ImageLoader from 'components/GatsbyImageLoader'

export default ({ image, title, buttonText, path }) => (
  <ImageLoader sizes={image.sizes}>
    {({ src }) => (
      <Section image={src}>
        <Title>{title}</Title>
        <Button to={path}>{buttonText}</Button>
      </Section>
    )}
  </ImageLoader>
)

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  height: 300px;
  margin: 8px 0;
`
const Title = styled.div`
  color: ${props => props.theme.accentColor1};
  font-weight: ${props => props.theme.demi};
  font-size: 16pt;
  letter-spacing: 0.5pt;
  text-align: center;
  width: 80%;
`
const Button = styled(Link)`
  margin-top: 30px;
  border: 1px solid ${props => props.theme.accentColor1};
  cursor: pointer;
  border-radius: 5px;
  font-size: 14.5pt;
  font-weight: ${props => props.theme.book};
  line-height: 5px;
  padding: 25px 20px;
  color: ${props => props.theme.accentColor1};
  transition-duration: 0.5s;

  &:hover {
    color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.accentColor1};
  }
`
