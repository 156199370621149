import React, { useState, createRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import animate from './animations'
import { ReactComponent as Logo } from 'assets/main-logo.svg'
import MenuIcon from './HamburgerIcon'

export default ({ routes }) => {
  const [open, setOpen] = useState(false)
  const menuTrayRef = createRef(null)
  const logoRef = createRef(null)

  function handleClick() {
    setOpen(open => !open)
    animate.toggleMobileNav(open, menuTrayRef.current, logoRef.current)
  }

  return (
    <Container>
      <Link to="/">
        <MainLogo />
      </Link>
      <MenuIcon open={open} click={handleClick} />
      <NavItems ref={menuTrayRef}>
        {routes.map((item, i) => (
          <NavLink key={i} to={item.path} onClick={handleClick}>
            {item.text}
          </NavLink>
        ))}
      </NavItems>
      <LogoContainer ref={logoRef}>
        <MainLogo2 />
      </LogoContainer>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.primaryColor};
  border-bottom: 3px solid ${props => props.theme.accentColor1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  @media (min-width: ${props => props.theme.desktop}px) {
    display: none;
  }
`
const MainLogo = styled(Logo)`
  width: 150px;
  height: 22px;
  fill: ${props => props.theme.accentColor1};
`
const LogoContainer = styled.div`
  position: fixed;
  right: 75px;
  bottom: -60px;
  opacity: 0;
  width: 150px;
  height: 26px;
`
const MainLogo2 = styled(Logo)`
  width: 150px;
  height: 26px;
  fill: ${props => props.theme.accentColor1};
`
const NavItems = styled.div`
  padding: 100px 0 50px;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100vh;
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
`
const NavLink = styled(Link)`
  margin: 50px 30px;
  font-size: 17pt;
  font-weight: 600;
  color: white;
  opacity: 0;
  display: none;
`
