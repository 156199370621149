import React, { Component, Children, createRef } from 'react'
import { Link } from 'gatsby'

import NavContext from './nav-context'

class NavLink extends Component {
  constructor(props) {
    super(props)
    this.ref = createRef(null)
  }

  onMouseEnter = () => {
    const { onHover, children } = this.props
    const { bottom, left } = this.ref.current.getBoundingClientRect()
    const dropdown = Children.toArray(children).slice(1)
    onHover({
      dropdown: dropdown.length === 0 ? null : dropdown,
      top: bottom,
      left
    })
  }

  render() {
    const { children, onHover, ...restProps } = this.props
    const child = Children.toArray(children)[0]
    return (
      <Link onMouseEnter={this.onMouseEnter} ref={this.ref} {...restProps}>
        {child}
      </Link>
    )
  }
}

export default props => (
  <NavContext.Consumer>
    {({ onHover }) => <NavLink {...props} onHover={onHover} />}
  </NavContext.Consumer>
)
