import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { ReactComponent as Logo } from 'assets/main-logo.svg'

import Navbar from './Nav'
import Link from './Link'

export default () => (
  <Nav dropdownComponent={DropdownContainer}>
    <GatsbyLink to="/">
      <MainLogo />
    </GatsbyLink>
    <NavItems>
      <NavLink to="/freight">FREIGHT</NavLink>
      <NavLink to="/warehousing">WAREHOUSING</NavLink>
      <NavLink to="/fulfillment">FULFILLMENT</NavLink>
      <NavLink to="/why-tugg">+ WHY TUGG</NavLink>
    </NavItems>
  </Nav>
)

const Nav = styled(Navbar)`
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  padding: 0 40px;
  background-color: ${props => props.theme.primaryColor};
  border-bottom: 5px solid ${props => props.theme.accentColor1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  @media (max-width: ${props => props.theme.desktop}px) {
    display: none;
  }
`
const MainLogo = styled(Logo)`
  width: 160px;
  height: 32px;
  fill: ${props => props.theme.accentColor1};
`
const NavItems = styled.div`
  width: 750px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const NavLink = styled(Link).attrs({
  activeClassName: 'navLinkActive'
})`
  margin: 0;
  padding: 32px 24px;
  font-size: 14px;
  line-height: 0;
  font-weight: ${props => props.theme.demi};
  color: ${props => props.theme.accentColor2};
  transition-duration: 0.5s;
  z-index: 2;

  &:hover {
    color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.accentColor1};
  }

  &:last-of-type {
    margin-left: 25px;
  }

  &.navLinkActive {
    color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.accentColor1};
  }
`
const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${props => props.left}px;
  top: ${props => props.top + 5}px;
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.accentColor1};
  transition-duration: 0.5s;
  box-shadow: ${props => props.theme.shadow};
`
